import { Badge } from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
const SettingNavSection = ({ item }) => {
  const { pathname } = useLocation();
  const { todosLength } = useSelector((state) => state.todolist);
  const { t } = useTranslation();
  const StyledBadge = withStyles(() => ({
    badge: {
      fontSize: "8px", // Adjust the font size as needed
      minWidth: "13px", // Adjust the width as needed
      height: "13px", // Adjust the height as needed
      backgrounColor: "#ED3863",
      fontWeight: "500",
      fontFamily: `"Poppins", sans-serif !important`,
      color: "#FFF",
    },
  }))(Badge);
  return (
    <RouterLink to={item.path}>
      <StyledBadge
        className={
          pathname.includes("/settings")
            ? pathname.includes(item.path)
              ? "nav-section-wrapper active-subnav"
              : "nav-section-wrapper"
            : pathname.includes(item.path)
            ? "todo-list-nav-section-wrapper todo-list-active-subnav badge-custom"
            : "todo-list-nav-section-wrapper badge-custom"
        }
        badgeContent={
          item?.path?.includes("/todo-list")
            ? (() => {
                return item.path?.includes("/open")
                  ? todosLength?.open
                  : item.path?.includes("/complete")
                  ? todosLength?.completed
                  : todosLength?.assignedToMe;
              })()
            : null
        }
        color="primary"
      >
        <LazyLoadImage
          src={item.icon}
          alt="account"
          width={pathname.includes("/settings") ? "40px" : undefined}
          height={pathname.includes("/settings") ? "40px" : undefined}
        />
        <div className="nav-section-text-wrapper">
          <span className="nav-title">
            {t(item.title).replace(" ", "\xa0")}
          </span>
          <span className="nav-subtitle">
            {t(item.subtitle).replace(" ", "\xa0")}
          </span>
        </div>
      </StyledBadge>
    </RouterLink>
  );
};

export default SettingNavSection;
