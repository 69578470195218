import React from "react";
import { styled } from "@mui/material/styles";
import data from "./../../utilities/constants";
import { SubSideBar, ContentHeader } from "../../components";
import { useTranslation } from "react-i18next";
const Settings = ({ children }) => {
  const { i18n } = useTranslation();

  const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    //  overflow: "hidden",
    [theme.breakpoints.up("xl")]:
      i18n.language === "ar"
        ? {
            width: "100%",
            //  paddingRight: "20px",
          }
        : {
            width: "100%",
            //  paddingLeft: "20px",
          },
  }));
  const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    height: "100%",
    width: "100%",
    maxHeight: "90vh",
    overflow: "auto",
    webkitOverflowScrolling: "touch",
    paddingTop: data.APP_BAR_MOBILE + 3,
  }));
  return (
    <RootStyle dir="auto">
      <SubSideBar />
      <div style={{ width: "100%" }}>
        <ContentHeader />
        <MainStyle>{children}</MainStyle>
      </div>{" "}
    </RootStyle>
  );
};

export default Settings;
